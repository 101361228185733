import React from "react"
import {graphql} from "gatsby"
import Newsletter from "../components/organisms/Newsletter";
import Seo from "../components/molecules/Seo";
import Header from "../components/molecules/Header";
import ColorScheme from "../components/atoms/ColorScheme";

function NewsletterPage({data}) {
  const newsletter = data.prismicNewsletter;
  const {edition, primary, secondary, og_image, og_description} = newsletter.data;
  return (
    <ColorScheme primary={primary} secondary={secondary}>
      <Seo
        title={edition.text}
        image={og_image && og_image.url}
        description={og_description && og_description.text} />
      <Header title={edition.text} topColor="yellow" bottomColor="purple"/>
      <Newsletter newsletter={newsletter} />
    </ColorScheme>
  )
}

export default NewsletterPage

export const pageQuery = graphql`
  query($id: String!) {
    prismicNewsletter(id: { eq: $id }) {
      data {
          primary
          secondary
          og_image {
            url(imgixParams: {height: 630, width: 1200})
          }
          og_description {
            text
          }
          edition {
            text
          }
          date {
            text
          }
          conclusion {
            raw
          }
          body {
            ... on PrismicNewsletterDataBodySection {
              id
              items {
                link {
                  document {
                    ... on PrismicLink {
                      id
                      tags
                      data {
                        title {
                          text
                        }
                        text {
                          raw
                        }
                        link {
                          url
                        }
                        layout
                        image {
                          alt
                          gatsbyImageData(layout: FULL_WIDTH)
                        }
                        author {
                          text
                        }
                      }
                    }
                  }
                }
              }
              primary {
                curator1 {
                  document {
                    ... on PrismicContributor {
                      id
                      data {
                        name {
                          text
                        }
                      }
                      url
                    }
                  }
                }
                section_title {
                  text
                }
                introduction {
                  raw
                }
              }
            }
          }
      }
    }
  }
`
