import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import PrismicRichText from "../atoms/PrismicRichText";
import {GatsbyImage} from "gatsby-plugin-image";
import {convertToSlug} from "../../utils/helpers";
import {Link} from 'gatsby';


const TextHolder = styled.div`
  h3 {
    margin: 0;
  }
  .author {
    font-style: italic;
    color: ${props => props.theme.colours.grey};
    margin: 1rem 0 2rem 0;
  }
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-column: 1/2;
  }
`;

const ImageHolder = styled.div`
  padding: 2rem 4rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-column: 2/3;
    padding: 0;
  }
`;

const ButtonHolder = styled.div`
  align-self: center;
`;

const Tags = styled.ul`
  list-style: none;
  padding-left: 0;
  color: ${props => props.theme.colours.grey};
  font-style: italic;
  margin: 0;
  align-self: center;
  @media( ${props => props.theme.breakpoints.md} ) {
    text-align: right;
  }
  li {
    display: inline-block;
    &:after {
      content: '•';
      margin: 0 0.5rem;
    }
    &:last-child:after {
      display: none;
    }
  }
`;


const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-bottom: 4rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
  }
  &.no-image {
    ${TextHolder} {
      @media( ${props => props.theme.breakpoints.md} ) {
        grid-column: 1/3;
      }
    }
  }
  &.image-is-a-book-cover {
    @media( ${props => props.theme.breakpoints.md} ) {
      grid-template-columns: repeat(4, 1fr);
      ${TextHolder} { grid-column: 1/4; }
      ${ImageHolder} { grid-column: 4/5; }
      ${ButtonHolder} { grid-column: 1/2; }
      ${Tags} { grid-column: 2/5; }
    }
  }
`;

function LinkCard({linkContent}) {
  const {title, text, link, layout, image, author} = linkContent.data;
  return (
    <Holder className={`link-card ${convertToSlug(layout)}`}>
      {image.gatsbyImageData && <ImageHolder>
        <GatsbyImage alt={image.alt || title.text} layout="constrained" image={image.gatsbyImageData} />
      </ImageHolder>}
      <TextHolder>
        <h3><a href={link.url} target="_blank" rel="noopener noreferrer">{title.text}</a></h3>
        <p className="author mono">{author.text}</p>
        <PrismicRichText render={text.raw} />
      </TextHolder>
      <ButtonHolder>
        <a className="button" href={link.url} target="_blank" rel="noopener noreferrer">Read more</a>
      </ButtonHolder>
      {linkContent.tags &&
      <Tags className="mono small">{linkContent.tags.map(tag => <li key={tag}><Link to={`/${convertToSlug(tag)}`}>{tag}</Link></li>)}</Tags>}
    </Holder>
  )
}

LinkCard.propTypes = {
  linkContent: PropTypes.object.isRequired,
};

export default LinkCard;