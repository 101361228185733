import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import Semi from '../../assets/svg/semicircle.inline.svg';

const Holder = styled.div`
  text-align: center;
  margin: 2rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    margin: 4rem 0;
  }
  svg {
    width: 2rem;
    height: auto;
    transform: ${props => props.flip ? 'rotate(180deg)' : 'none'};
    transform-origin: center center;
    path {
      fill: ${props => props.theme.primary};
    }
  }
`;

function SemiCircle({rotate}) {
  return (
    <Holder className="semi-circle" flip={rotate}>
      <Semi/>
    </Holder>
  )
}

SemiCircle.propTypes = {
  rotate: PropTypes.bool,
};

SemiCircle.defaultProps = {
  rotate: false,
};

export default SemiCircle;