import React from 'react';
import PropTypes from "prop-types";
import Container from "../atoms/Container";
import NewsletterSection from "./NewsletterSection";
import PrismicRichText from "../atoms/PrismicRichText";


function Newsletter({newsletter}) {
  const {
    date,
    body,
    conclusion
  } = newsletter.data;

  return (
    <Container>
      {body.length > 0 && body.map((node, i) =>
        <NewsletterSection key={node.id} section={node} date={date} primary={i === 0} />
      )}
      {conclusion.raw[0] && <div className="mono large"><PrismicRichText render={conclusion.raw} /></div>}
    </Container>
  )
}

Newsletter.propTypes =
  {
    newsletter: PropTypes.object.isRequired,
  }
;

export default Newsletter;