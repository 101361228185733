import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import SemiCircle from "../atoms/SemiCircle";
import PrismicRichText from "../atoms/PrismicRichText";
import LinkCard from "../molecules/LinkCard";

const Holder = styled.div`
  border-bottom: 2px solid ${props => props.theme.secondary};
  margin-bottom: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-bottom: 4rem;
  }
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const Intro = styled.div`
  h2, .details {
    text-align: center;
  }

  border-bottom: 2px solid ${props => props.theme.primary};
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-bottom: 4rem;
    padding-bottom: 4rem;
  }
`;

const Links = styled.div`
  > div:last-child .semi-circle{
    display: none;
  }
  > div:nth-child(odd) {
    .semi-circle path {
      fill: ${props => props.theme.secondary};
    }
  }
  > div:nth-child(even) {
    .button {
      background-color: ${props => props.theme.secondary};
    }
  }
  
`;

function NewsletterSection({section, date, primary}) {
  const { section_title, curator1, introduction } = section.primary;
  const links = section.items;
  if ( !section_title || !curator1.document || !links ) return null;
  return (
    <Holder className="newsletter-section">
      <Intro>
        <h2>{section_title.text}</h2>
        <p className="details mono large">{curator1.document.data.name.text}{primary && ` • ${date.text}`}</p>
        <SemiCircle />
        {introduction && <div className="mono large"><PrismicRichText render={introduction.raw} /></div>}
      </Intro>
      <Links>
        {links && links.map((link, i) =>
          <div key={link.link.document.id}>
            <LinkCard linkContent={link.link.document} />
            <SemiCircle rotate={i % 2 === 1} />
          </div>
        )}
      </Links>
    </Holder>
  )
}

NewsletterSection.propTypes = {
  section: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  primary: PropTypes.bool,
};

NewsletterSection.defaultProps = {
  primary: false,
};

export default NewsletterSection;